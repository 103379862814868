import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { ListGroup } from 'react-bootstrap';
import {
  AiOutlineMail,
  AiOutlineWhatsApp,
  AiFillPhone,
} from "react-icons/ai";

import myImg from "../../Assets/Avatar.png";
import Tilt from "react-parallax-tilt";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

import "./home.css";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Advogado, assistência em INSS e especialista em Direito Previdenciário{" "}
              </h1>
              <h1 className="heading-name">
                <strong className="main-name">FERNANDO JOSÉ BRANDÃO TEIXEIRA - Oab-Rj n° 097.140</strong>
              </h1>
              <h1 style={{ paddingBottom: 15 }} className="heading">
               Consulta Gratuita{" "}
              </h1>
            </Col>
            <Col md={5} className="myAvtar">
            <Tilt>
              <img
                data-aos="fade-left"
                src={myImg}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
          </Row>
          <Row>
          <Col md={12} className="home-about-social">
            <h1 data-aos="fade-right">
              <span className="primary-header">Contato</span>
            </h1>
            <ul className="home-about-social-links" data-aos="fade-up">
              <li className="social-icons">
                <a
                  href="mailto:escritorio@assistenciaprevidenciaria.com.br"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="email"
                >
                  <AiOutlineMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:21988557166"
                  rel="noopener noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="telephone"
                >
                  <AiFillPhone />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send?1=pt_BR&phone=5521987277009"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                  aria-label="whatsapp"
                >
                  <AiOutlineWhatsApp />
                </a>
              </li>
            </ul>
            <p data-aos="fade-left">escritorio@assistenciaprevidenciaria.com.br <br/>(21) 9-8727-7009, Carlos Eduardo e (21) 9-8855-7166, Doutor Fernando</p>
            <p data-aos="fade-left"><b>Atendimento, online e presencial: Rio de Janeiro e Grande Rio e interior do Estado</b></p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-description">
            <ListGroup className="custom-list-group">
              <ListGroup.Item className="custom-list-group-item">
                <strong>INSS, em Geral</strong>
                <ListGroup>
                  <ListGroup.Item>Consultoria e orientação sobre processos e procedimentos junto ao INSS, a busca pelo melhor benefício ao cliente.</ListGroup.Item>
                  <ListGroup.Item>Assistência e acompanhamento além da defesa dos direitos dos nossos clientes em busca do Benefício Previdenciário que lhe for mais vantajoso.</ListGroup.Item>
                  <ListGroup.Item>Pedidos administrativos e ações judiciais, em questões previdenciárias.</ListGroup.Item>
                  <ListGroup.Item>Ações judiciais são interpostas, via de regra, caso o pedido administrativo seja negado pelo INSS.</ListGroup.Item>
                </ListGroup>
              </ListGroup.Item>
              <ListGroup.Item className="custom-list-group-item">
                <strong>Benefícios Assistenciais, BPC / LOAS</strong>
                <ListGroup>
                  <ListGroup.Item>Benefício de Prestação Continuada - 1 salário mínimo por mês, BPC / LOAS, para pessoas de baixa renda (idosos acima dos 65 anos e portadores de deficiência grave ou de doença grave).</ListGroup.Item>
                  <ListGroup.Item>Assessoria completa na solicitação destes benefícios, inclusive para a entrada de Ação Judicial em caso de negativa do pedido administrativo pelo INSS.</ListGroup.Item>
                </ListGroup>
              </ListGroup.Item>
              <ListGroup.Item className="custom-list-group-item">
                <strong>Aposentadoria por Tempo de Contribuição, Aposentadoria por Idade, Aposentadoria Especial, Pensão por Morte</strong>
                <ListGroup>
                  <ListGroup.Item>Orientação e cálculo para a concessão da aposentadoria, inclusive buscando a que for mais vantajosa para o segurado do INSS.</ListGroup.Item>
                  <ListGroup.Item>Assessoria e suporte na busca dos documentos necessários e a sua análise para a aposentadoria, inclusive para a Aposentadoria Especial.</ListGroup.Item>
                  <ListGroup.Item>Averbações dos períodos trabalhados ou em outros regimes previdenciários ou em atividades especiais (insalubres, periculosas, etc).</ListGroup.Item>
                  <ListGroup.Item>Busca, inclusive, pelo reconhecimento dos tempos especiais de serviço (atividades insalubres e / ou periculosas, etc) e o que pode antecipar a aposentadoria ou aumentar a sua renda mensal.</ListGroup.Item>
                  <ListGroup.Item>Pensão por Morte para os dependentes do segurado falecido: o cônjuge, os filhos menores de idade e os filhos maiores inválidos.</ListGroup.Item>
                  <ListGroup.Item>Ação judicial ou para a concessão ou para a revisão destes benefícios caso o benefício tenha sido negado pelo INSS administrativamente.</ListGroup.Item>
                </ListGroup>
              </ListGroup.Item>
              <ListGroup.Item className="custom-list-group-item">
                <strong>Benefícios por incapacidade, Auxílio-Doença / Auxílio-Acidente e Aposentadoria por Invalidez</strong>
                <ListGroup>
                  <ListGroup.Item>Pedidos administrativos junto ao INSS para a concessão de Auxílio-Doença e de Auxílio-Acidente e de Aposentadoria por Invalidez.</ListGroup.Item>
                  <ListGroup.Item>Assistência jurídica e suporte em casos de necessidade de prorrogação do benefício por cessão indevida ou, se for o caso, da sua conversão em Aposentadoria por Invalidez.</ListGroup.Item>
                  <ListGroup.Item>Ações judiciais em caso de negativa do pedido administrativo ou para a revisão destes benefícios.</ListGroup.Item>
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </Container>
    <ScrollToTop />
    </section>
  );
}
export default Home;
